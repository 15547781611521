﻿@use "../settings";
@use "../mixins";

@include mixins.tablet-up {
    #divNavigationPrimaryContainer {
        position: static;
        order: 6;
        width: 100%;
        background: settings.$color-hover;
    }

    #divNavigationPrimary {
        max-width: 1000px;
        padding-top: 0;
        padding-bottom: 0;

        .mobile-menu-toggle,
        > a {
            display: none;
        }

        .nav-items {
            font-size: 1.4rem;
            text-transform: uppercase;
            font-weight: 600;
        }
    }

    #mobile-menu {
        ~ .nav-items {
            transform: none;
            position: static;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .nav-item {
                position: relative;

                &.clsHome {
                    a {
                        width: 21px;
                        text-indent: 150%;
                        white-space: nowrap;
                        overflow: hidden;

                        &::before,
                        &::after {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 100%;
                            background: url(../images/icon_home_navigation.png) no-repeat 0 16px;
                            transition: opacity ease-in-out 0.25s;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }

                        &::after {
                            background-position-y: -165px;
                            opacity: 0;
                        }

                        &:hover {
                            &::before {
                                opacity: 0;
                            }

                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }

                .nav-items {
                    a {
                        display: block;
                        padding-right: 32px;
                        position: relative;
                        width: 100%;

                        &::after {
                            content: "";
                            display: block;
                            border-top: 2px solid #fff;
                            border-right: 2px solid #fff;
                            width: 8px;
                            height: 8px;
                            transform: rotate(45deg) translateY(-50%);
                            top: 50%;
                            right: 0;
                            opacity: 0;
                            position: absolute;
                            transition: opacity ease-in-out 0.25s;
                        }
                    }

                    .nav-item {
                        &:hover {
                            > a {
                                color: settings.$color-hover;
                                &::after {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }

                &:hover {
                    > a {
                        color: #8d9659;
                    }
                }
            }

            .nav-items {
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                border: 4px solid #a4ac75;
                border-top-width: 0;
                border-bottom-width: 0;
                padding: 0 24px;
                transition: border ease-in-out 0.25s, max-height ease-in-out 0.25s, padding ease-in-out 0.25s;
                background: #787e56;
                white-space: nowrap;
            }

            .dropdown-toggle {
                &[checked],
                &:checked {
                    ~ .nav-items {
                        border-top-width: 4px;
                        border-bottom-width: 4px;
                        padding-top: 18px;
                        padding-bottom: 18px;
                        overflow: visible;

                        &::before {
                            position: absolute;
                            top: -4px;
                            left: 50%;
                            transform: translate(-50%, -100%);
                            content: "";
                            display: block;
                            border: 6px solid transparent;
                            border-top: none;
                            border-bottom-color: #a4ac75;
                        }
                    }
                }
            }
        }
    }
}