﻿@use "../settings";
@use "../mixins";

@include mixins.desktop-up {
    #divMeta {
        &::after {
            display: none;
        }
    }

    #google_translate_element {
        margin-right: 37px;
    }
}