﻿html, body, form {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100%;
    color: #363636;
    max-width: 100%;
}

html {
    font-size: 10px;
}

body {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 1.5rem;
    position: relative;
    top: 0;
    left: 0;
    line-height: 1.5em;
    overflow-x: hidden;
}

.translated-rtl {
    direction: rtl
}

.translated-ltr {
    direction: ltr;
}

a {
    transition: opacity cubic-bezier(0,0,0,1) 0.25s, color cubic-bezier(0,0,0,1) 0.25s, background-color cubic-bezier(0,0,0,1) 0.25s, border-color cubic-bezier(0,0,0,1) 0.25s, left cubic-bezier(0,0,0,1) 0.25s, top cubic-bezier(0,0,0,1) 0.25s;
    text-decoration: none;
    color: #787800;
}

a:hover {
    color: #a4ac75;
}

a::before,
a::after,
input[type="button"] {
    transition: opacity cubic-bezier(0,0,0,1) 0.25s, color cubic-bezier(0,0,0,1) 0.25s, background-color cubic-bezier(0,0,0,1) 0.25s, border-color cubic-bezier(0,0,0,1) 0.25s, left cubic-bezier(0,0,0,1) 0.25s, top cubic-bezier(0,0,0,1) 0.25s;
}

strong {
    color: #2c2c0e;
}

img {
    border: none;
}

h1 {
    font-size: 3.0rem;
    font-weight: normal;
    color: #787800;
    margin: 0 0 32px 0;
    line-height: 1.2em;
}

h2 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #787800;
    margin: 48px 0 16px 0;
    line-height: 1.2em;
}

h3 {
    font-size: 1.6rem;
    font-weight: 600;
    color: #787800;
    margin: 0 0 16px 0;
    line-height: 1.2em;
}

h4 {
    font-size: 1.6rem;
    font-weight: normal;
    color: #787800;
    margin: 0 0 16px 0;
    line-height: 1.2em;
}

#divContainer {
    background: #ffffff;
}

#divFontSize {
    float: right;
    font-weight: 600;
    display: flex;
    justify-content: space-around;
    width: 50%;
}

#divFontSize .clsFontSizeText {
    display: block;
    float: left;
}

#divFontSize .clsSmaller,
#divFontSize .clsLarger {
    width: 2.2rem;
    display: block;
    line-height: 2.4rem;
    position: relative;
    color: #8d9659;
    overflow: hidden;
    text-align: center;
    font-size: 1.6rem;
}

#divFontSize .clsSmaller::before,
#divFontSize .clsLarger::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -1rem;
    font-size: 2.0rem;
    display: block;
    border: 0.2rem solid #8d9659;
    border-radius: 50%;
    height: 1.8rem;
    width: 1.8rem;
    text-indent: 0;
    text-align: center;
}

#divFontSize .clsSmaller:hover,
#divFontSize .clsLarger:hover {
    color: #a4ac75;
}

#divFontSize .clsSmaller:hover::before,
#divFontSize .clsLarger:hover::before {
    border-color: #a4ac75;
}

#divBreadcrumb {
    position: relative;
    top: 0;
    left: 0;
    font-size: 1.2rem;
    margin: 0;
    line-height: 1.2rem;
    margin: 26px 0
}

#divBreadcrumb ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#divBreadcrumb ul li {
    display: inline-block;
    color: #787800;
}

#divBreadcrumb ul li::after {
    content: " /";
}

#divBreadcrumb ul li:last-child::after {
    content: "";
}

#divBreadcrumb ul li a {
    color: #787800;
}

#divBreadcrumb ul li a:hover,
#divBreadcrumb ul li:last-child {
    color: #a4ac75;
}

#divContent ul {
    padding: 0 0 0 48px;
    margin: 24px 0;
}

#divContent ul li {
    font-size: 1.0rem;
    margin: 8px 0;
}

#divContent ul li span {
    font-size: 1.5rem;
}

#divFooterContainer {
    background: url(../images/bg_footer_container.png) repeat top left;
    padding: 30px 0;
}

#divContactInfo {
    margin-bottom: 22px;
}

#divContactInfo:after,
#divContactInfo::after {
    content: "";
    position: relative;
    clear: both;
    display: block;
}

#divContactInfo > img {
    float: left;
}

#divContactInfo .clsFooterContact {
    float: left;
}

#divContactInfo .clsFooterContact h4 {
    font-size: 1.6rem;
    color: #363636;
    font-weight: normal;
    margin: 0 0 16px 0;
}

#divContactInfo .clsFooterContact h4 a {
    color: #9e9e00;
}

#divContactInfo .clsFooterContact p {
    font-size: 1.5rem;
    color: #4d541f;
    margin: 0;
}

#divContactInfo .clsFooterContact p a {
    color: #4d541f;
}

#divContactInfo .clsFooterContact p a:hover {
    color: #9e9e00;
}

#divPrivacyPolicy {
    font-size: 1.2rem;
    color: #797979;
    padding-left: 32px;
    background: url(../images/bg_lock.png) no-repeat left center;
    margin-bottom: 19px;
    line-height: 1.2em;
}

#divHighContrast {
    width: 50%;
    float: left;
}

#divHighContrast .clsContrastHigh {
    display: inline-block;
    padding: 0 8px;
    line-height: 2.3rem;
    background: #adacac;
    color: #ffffff;
    font-size: 1.1rem;
}

#divHighContrast .clsContrastHigh:hover {
    background-color: #a4ac75;
}

#divFooterText {
    font-size: 1.5rem;
    line-height: 2.4rem;
}

#divFooterText h3 {
    font-size: 1.6rem;
    color: #363636;
    font-weight: normal;
    margin: 0 0 16px 0;
    line-height: 1.8rem;
}

#divFooterText .links-title {
    margin: 0;
    color: #555555;
}

#divFooterText table {
    width: 100%;
}

#divFooterText table tr td {
    padding-right: 6.6%;
    width: 33%;
    vertical-align: top;
}

#divFooterText ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

#divFooterText a {
    color: #4d541f;
    display: block;
}

#divFooterText a:hover {
    color: #9e9e00;
}

#divFooterText ul .clsHasDropdown {
    height: 0;
    overflow: hidden;
    transition: height cubic-bezier(0,0,0,1) 0.375s;
}

#divFooterText ul ul {
    padding-left: 6px;
}

#divFooterText ul li {
    position: relative;
    top: 0;
    left: 0;
}

#divFooterText .clsDropdownArrow a::after {
    content: "";
    background: url(../images/icon_dropdown_arrow.png) no-repeat top left;
    width: 12px;
    height: 7px;
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -3.5px;
    transition: opacity cubic-bezier(0,0,0,1) 0.25s;
    opacity: 0.2;
}
#divFooterText .clsDropdownArrowRemove a::after {
    content: "";
    background: url(../images/icon_dropdown_arrow.png) no-repeat top left;
    width: 12px;
    height: 7px;
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -3.5px;
    transition: opacity cubic-bezier(0,0,0,1) 0.25s;
    opacity: 0.2;
    transform: rotate(180deg);
}

#divFooterText .clsDropdownArrow a:hover::after {
    opacity: 1;
}

#divFooterText .clsDropdownArrowRemove a:hover::after {
    opacity: 1;
}

#divFooterText .footer-links ul {
    width: 32%;
    display: inline-block;
    vertical-align: top;
    ul {
        width: 100%;
    }
}

.clsLogoFooter {
    float: left;
    margin-right: 12px;
}

.clsClear {
    clear: both;
}

.clsHighContrast {
    display: none !important;
    visibility: hidden !important;
}

.clsMobileMenuIcon {
    background: url(../images/icon_mobile_navigation.png) no-repeat left center;
    padding-left: 38px;
    height: 48px;
    float: left;
    margin-left: 1%;
    cursor: pointer;
    font-weight: bold;
    color: #ffffff;
}

.clsMobileMenu {
    background: url(../images/bg_navigation_primary.png) repeat top left;
    color: #ffffff;
    padding: 18px;
    min-width: 75%;
    max-width: 75%;
    height: 100%;
}

.clsMobileMenu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 2rem;
    line-height: 2em;
    font-weight: bold;
}

.clsMobileMenu .clsDropdownHandler::after {
    font-size: 2.5rem;
    top: -4px;
}

.clsMobileMenu ul li {
    margin: 18px 0;
    line-height: 1.2;
    padding-right: 30px;
}

.clsMobileMenu ul ul {
    font-size: 2rem;
    font-weight: normal;
    padding: 0 0 0 10px;
}

.clsMobileMenu ul ul li {
    margin: 2px 0;
}

.clsMobileMenu ul ul a {
    padding: 5px;
    display: inline-block;
}

.clsMobileMenu a {
    color: #ffffff;
}

.clsMobileMenu .clsCurrent > a {
    color: #bdc96f;
}

.clsPopupContainer {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background: rgba(136, 145, 80, 0.95);
    transition: bottom ease 0.8s, opacity ease 0.8s;
}

.clsPopupContainer.clsExpanded {
    z-index: 1000;
    bottom: 0;
}

.clsPopupContainer .clsPopup {
    position: relative;
    left: 0;
    bottom: 0;
    padding: 40px 0;
    position: relative;
    font-size: 1.4rem;
    line-height: 1.5em;
    color: #ffffff;
}

.clsPopupContainer .clsPopup strong {
    color: #ffffff;
}

.clsPopupContainer .clsPopup p {
    margin: 0;
    padding-bottom: 32px;
}

.clsPopupContainer .clsPopup input[type="checkbox"] {
    display: none;
    visibility: hidden;
}

.clsPopupContainer .clsPopup input[type="checkbox"] + label {
    padding-left: 34px;
    line-height: 20px;
    position: relative;
    display: block;
    float: left;
}

.clsPopupContainer .clsPopup input[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 2px solid #ffffff;
}

.clsPopupContainer .clsPopup input[type="checkbox"] + label::after {
    content: "✓";
    position: absolute;
    top: 10px;
    left: 12px;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%,-50%);
    color: #0a245f;
    font-weight: bold;
    opacity: 0;
}

.clsPopupContainer .clsPopup input[type="checkbox"].checked + label::after {
    opacity: 1;
}

.clsPopupContainer .clsPopup a {
    color: #ffffff;
}

.clsPopupContainer .clsPopup a.white {
    color: #ffffff;
    text-decoration: underline;
}

.clsPopupContainer .clsPopup .clsClose {
    float: right;
    position: relative;
}

.clsPopupContainer .clsPopup .clsClose:before,
.clsPopupContainer .clsPopup .clsClose::before {
    content: "X";
    display: block;
    position: absolute;
    right: 0;
    top: -15px;
    font-size: 1.45em;
    font-weight: bold;
    transition: none;
}

.clsPopupContainer .clsPopup .clsPopupContent {
    padding: 0 4%;
}

.clsPopupContainer .clsPopup a:hover,
.clsPopupContainer .clsPopup a:hover:before,
.clsPopupContainer .clsPopup a:hover::before {
    color: #a4ac75;
}

.blockquote {
    display: block;
    border: 1px dashed #cccccc;
    border-left-width: 0;
    border-right-width: 0;
    background: #f9f7f7;
    text-align: center;
    padding: 15.5px 2%;
    color: #363636;
    margin: 32px 0;
}

.filter-container {
    margin-bottom: 16px;
}

.highlight {
    color: #787800;
    padding: 0 2px;
    background: #efefef;
}

dt .highlight,
.FAQSectionHeading .highlight {
    background: #a4ac75;
    color: #efefef;
}

.filter-container input {
    padding: 8px 12px;
    font-family: "Open Sans", sans-serif;
}

@media only screen and (min-width: 768px) {
    .filter-container input {
        width: 50%;
    }
}
