﻿

#divContent .accordion-container {
    padding: 0;
    margin: 0;
    list-style: none;
}

#divContent .accordion dd li {
    font-size: inherit;
    list-style: disc;
}

.accordion:first-child {
    margin-top: 0;
}

.accordion:last-child {
    margin-bottom: 0;
}

.accordion dt {
    font-weight: bold;
    font-size: 1em;
    position: relative;
    cursor: pointer;
    padding: 1em 8.25em 1em 1.33em;
    background: #F9F7F7;
    color: #363636;
    transition: color cubic-bezier(0, 0, 0, 1) 0.25s;
}

.accordion dt:before {
    content: "";
    pointer-events: none;
    position: absolute;
    right: 1.33em;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: opacity cubic-bezier(0, 0, 0, 1) 0.25s;
    width: 13px;
    height: 16px;
    background: url(../images/back-black.svg) no-repeat center center;
}

.accordion dt::before {
    content: "";
    pointer-events: none;
    position: absolute;
    right: 1.33em;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: opacity cubic-bezier(0, 0, 0, 1) 0.25s;
    width: 13px;
    height: 16px;
    background: url(../images/back-black.svg) no-repeat center center;
}

.accordion dt:after {
    content: "";
    pointer-events: none;
    position: absolute;
    right: 1.33em;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: opacity cubic-bezier(0, 0, 0, 1) 0.25s;
    width: 13px;
    height: 16px;
    background: url(../images/back.svg) no-repeat center center;
    opacity: 0;
}

.accordion dt::after {
    content: "";
    pointer-events: none;
    position: absolute;
    right: 1.33em;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: opacity cubic-bezier(0, 0, 0, 1) 0.25s;
    width: 13px;
    height: 16px;
    background: url(../images/back.svg) no-repeat center center;
    opacity: 0;
}

.accordion dd {
    max-height: 0;
    overflow: hidden;
    padding: 0 1.25em;
    transition: max-height cubic-bezier(0,0,0,1) 0.5s, padding cubic-bezier(0, 0, 0, 1) 0.5s;
    margin: 0;
}

.accordion dd p:first-child {
    margin-top: 0;
}

.accordion dd p:last-child {
    margin-bottom: 0;
}

#divContent .accordions li {
    font-size: 1.5rem;
}


.accordion.expanded dt {
    color: #787800;
}

.accordion.expanded dt:before {
    opacity: 0;
}

.accordion.expanded dt::before {
    opacity: 0;
}

.accordion.expanded dt:after {
    opacity: 1;
}

.accordion.expanded dt::after {
    opacity: 1;
}

.accordion.expanded dd {
    max-height: 1000px;
    padding: 0.5em 1.25em;
}
