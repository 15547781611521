﻿@media all and (max-width: 960px) {
    #divContactInfo .clsFooterContact {
        margin-top: 20px;
    }
}

@media all and (max-width: 768px) {
    #divFooterInfo {
        text-align: center;
    }

    #divContactInfo > img, .clsLogoFooter, #divContactInfo .clsFooterContact {
        float: none;
    }

    #divFooterText .footer-links ul {
        text-align: center;
    }
}

@media all and (max-width: 668px) {
    #divFooterText tbody,
    #divFooterText table,
    #divFooterText tr,
    #divFooterText table tr td {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
        padding: 0;
    }

    #divFooterText h3 {
        text-align: center;
    }

    #divHighContrast .clsContrastHigh,
    #divHighContrast .clsContrastLow {
        display: block;
        text-align: center;
    }

    #divFooterText {
        text-align: center;
    }

    #divFooterText .clsDropdownArrow a::after {
        content: none;
    }

    #divFooterText table a {
        display: inline-block;
        padding: 5px;
    }
}

@media all and (max-width: 520px) {
    #divFooterText .footer-links ul {
        width: 48%;
    }
}
