﻿@use "../settings";
@use "../mixins";

@include mixins.desktop-up {
    #divNavigationPrimary {
        .nav-items {
            font-size: 1.6rem;

            .dropdown-toggle {
                & + label {
                    display: none;
                    visibility: hidden;
                }
            }

            .nav-item {
                &:hover {
                    .nav-items {
                        max-height: var(--var-max-height);
                        padding-top: 18px;
                        padding-bottom: 18px;
                        border-top-width: 4px;
                        border-bottom-width: 4px;
                        overflow: visible;

                        &::before {
                            position: absolute;
                            top: -4px;
                            left: 50%;
                            transform: translate(-50%, -100%);
                            content: "";
                            display: block;
                            border: 6px solid transparent;
                            border-top: none;
                            border-bottom-color: #a4ac75;
                        }
                    }
                }
            }
        }
    }
}