﻿@use "../settings";
@use "sass:math";

#divHeaderContainer {
    padding-top: 118.5px;
    padding-bottom: 30px;
}

#divLogo {
    display: none;
}

#google_translate_element {
    height: 1.9rem;
    overflow: hidden;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        border: 4px solid transparent;
        border-bottom: none;
        border-top-color: settings.$color-primary;
        pointer-events: none;
    }

    .goog-te-combo {
        border: none;
        padding: 0 0 0 25px;
        margin: 0;
        background: url(../images/global.svg) no-repeat left center transparent;
        font-size: 1.4rem;
        line-height: 1.9rem;
        font-family: settings.$font-family;
        color: settings.$color-primary;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        outline: none !important;
        cursor: pointer;

        &::-ms-expand {
            display: none;
        }
    }

    .goog-te-gadget {
        color: transparent;

        > span {
            display: none;
        }
    }
}

#divMeta {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 27px;

    #pnlLanguage {
        margin-right: 30px;
    }

    &::after {
        content: "";
        display: block;
        order: 2;
        flex-basis: 100%;
        margin: 14px 0;
    }

    > * {
        order: 3;
    }

    .clsContactUs {
        color: #ADACAC;
        font-size: 1.6rem;
        display: block;
        padding-left: 28px;
        position: relative;
        margin-right: 30px;

        &::before,
        &::after {
            content: "";
            display: block;
            width: 17px;
            height: 15.5px;
            background: url(../images/icon-email-grey.svg) no-repeat center center / cover;
            transition: opacity ease-in-out 0.25s;
            left: 0;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
        }

        &::after {
            opacity: 0;
            background-image: url(../images/icon-email-hover.svg);
        }

        &:hover {
            color: settings.$color-primary;

            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
            }

            &::before {
            }
        }
    }

    .clsIcon {
        display: block;
        width: 21px;
        height: 21px;
        position: relative;
        white-space: nowrap;
        text-indent: 150%;
        overflow: hidden;
        margin: 0 2px;

        &:last-child {
            margin-right: 0;
        }

        &::before,
        &::after {
            content: "";
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url(../images/sprite_social_media.png) no-repeat 0 0;
            transition: opacity ease-in-out 0.25s;
            position: absolute;
        }

        &::after {
            opacity: 0;
            background-position-y: -22px;
        }

        &:hover {
            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
            }
        }

        &.clsLinkedInButton {
            &::before,
            &::after {
                background-position-x: -42px;
            }
        }

        &.clsRSSButton {
            &::before,
            &::after {
                background-position-x: -21px;
            }
        }

        &.clsSearchButton {
            background: url(../images/icon_magnifying_glass.png) no-repeat center center #adacac;
            background-size: 50%;

            &:hover {
                background-color: #0084b4;
            }

            &::before,
            &::after {
                display: none;
            }
        }
    }
}

#dropdown-language-control {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        pointer-events: none;
        display: block;
        border: 4px solid transparent;
        border-bottom: none;
        border-top-color: settings.$color-hover;
    }

    .combo {
        margin: 0;
        padding: 0 0 0 29px;
        background: transparent url(../images/global.svg) no-repeat left center;
        border: none;
        color: settings.$color-hover;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 1.6rem;
        line-height: math.div(18, 14);
        font-family: settings.$font-family;
        width: 100%;
        outline: none !important;
        cursor: pointer;
        padding-right: 12px;

        &::-ms-expand {
            display: none;
        }
    }
}

#divSearch {
    display: flex;
    justify-content: center;

    .clsProactiveDisclosureButton {
        display: block;
        line-height: 43px;
        height: 43px;
        padding: 0 15px 0 41px;
        background: #787e56;
        color: #ffffff;
        margin: 0;
        position: relative;
        text-transform: uppercase;

        &::before {
            content: "";
            background: url(../images/icon_proactive_disclosure.png);
            position: absolute;
            left: 17px;
            top: 50%;
            transform: translateY(-50%);
            width: 15px;
            height: 16px;
        }

        &:hover {
            background-color: #a4ac75;
        }
    }
}