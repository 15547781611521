﻿@use "mixins";

.clsMaxWidth {
    width: 100%;
    padding-left: 22px;
    padding-right: 22px;
}

@include mixins.tablet-up {
    .clsMaxWidth {
        padding-left: 55px;
        padding-right: 55px;
    }
}

@include mixins.desktop-up {
    .clsMaxWidth {
        padding-left: 0;
        padding-right: 0;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }
}

.mt-1 {
    margin-top: 0.25em !important;
}

.mt-2 {
    margin-top: 0.5em !important;
}

.mt-3 {
    margin-top: 0.75em !important;
}

.mt-4 {
    margin-top: 1em !important;
}

.mb-1 {
    margin-bottom: 0.25em !important;
}

.mb-2 {
    margin-bottom: 0.5em !important;
}

.mb-3 {
    margin-bottom: 0.75em !important;
}

.mb-4 {
    margin-bottom: 1em !important;
}

.my-1 {
    margin-top: 0.25em !important;
    margin-bottom: 0.25em !important;
}

.my-2 {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}

.my-3 {
    margin-top: 0.75em !important;
    margin-bottom: 0.75em !important;
}

.my-4 {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
}

.button {
    display: inline-block;
    line-height: 43px;
    height: 43px;
    background: #787e56;
    color: #ffffff;
    margin: 0;
    position: relative;
    text-transform: uppercase;
    transition: background 0.25s ease;
}

.button:hover {
    background: #a4ac75;
}

.button a {
    color: #ffffff;
    display: inline-block;
    padding: 0 15px;
}
