﻿@use "../settings";

#divNavigationPrimaryContainer {
    position: fixed;
    top: 0;
    left: 0;
    order: 1;
    width: 100%;
    background: settings.$color-hover;
    z-index: 1000;
}

.clsMainArea {
    .skiptranslate + & {
        #divNavigationPrimaryContainer {
            top: 40px;
        }
    }
}

#divNavigationPrimary {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    max-width: 100%;

    > a {
        &,
        img {
            display: block;
        }
    }

    .nav-items {
        font-size: 1.8rem;
        font-weight: 600;

        a {
            color: #fff;
            display: block;
            padding: 12px 0;
        }

        .nav-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .nav-items {
                flex-basis: 100%;
            }
        }

        .dropdown-toggle {
            display: none;
            visibility: hidden;

            + label {
                padding: 12px;
                display: block;

                &::before {
                    content: "";
                    display: block;
                    border: 4px solid transparent;
                    border-bottom: none;
                    border-top-color: #fff;
                }
            }

            ~ .nav-items {
                overflow: hidden;
                max-height: 0;
                transition: max-height ease-in-out 0.25s, padding ease-in-out 0.25s, border-width ease-in-out 0.25s;
            }

            &[checked],
            &:checked {
                + label {
                    &::before {
                        border-bottom: 4px solid #fff;
                        border-top: none;
                    }
                }

                ~ .nav-items {
                    max-height: var(--var-max-height);
                }
            }
        }
    }
}

#mobile-menu {
    display: none;
    visibility: hidden;

    + [for="mobile-menu"] {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 22px;
        cursor: pointer;
        z-index: 1001;
        position: relative;

        span {
            display: block;
            width: 100%;
            height: 4px;
            background: #fff;
            border-radius: 2px;
            transition: transform ease-in-out 0.25s;
        }
    }

    ~ .nav-items {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        transition: transform ease-in-out 0.25s;
        z-index: 1000;
        background: settings.$color-hover;
        margin: 0;
        padding: 82px 22px;
        list-style: none;
    }

    &[checked],
    &:checked {
        + [for="mobile-menu"] {
            position: fixed;
            right: 22px;

            span {
                display: none;

                &:first-child {
                    display: block;
                    transform: translateY(225%) rotate(45deg);
                }

                &:last-child {
                    display: block;
                    transform: translateY(-225%) rotate(-45deg);
                }
            }
        }
        ~ .nav-items {
            transform: none;
        }
    }
}